<template>
  <div class="container-fluid">
      <div class="container-wrapper">
          <div class="row">
              <div class="float-right text-right">
                  <router-link to="/add-payment">
                      <button class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-plus"></i></span> Payments</button>
                      <button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3 ml-1"><span><i class="fa fa-print"></i></span> Print</button>
                  </router-link>
              </div>
          </div>
          <div class="table-responsive">
              <datatable class="mt-2 table-responsive" title="Employee Payment List" :columns="tableColumns1" :rows="finalPayments" :exactSearch='true' :exportable="true" :printable="false" id="print">
                  <th slot="thead-tr">
                      Actions
                  </th>
                  <template slot="tbody-tr" scope="props">
                      <td>
                          <button class="data-btn mr-2" @click="e => viewItem(props.row, e)">
                              <i class="fa fa-eye"></i> View
                          </button>
                      </td>
                  </template>
              </datatable>
          </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTable from "vue-materialize-datatable";

export default {
  name: 'Attendance',
  data: () => ({
      tableColumns1: [{
              label: "Sl",
              field: "sno",
              value: 'sno',
              html: false
          },
          {
              label: "Employee ID",
              field: "employee_id",
              numeric: false,
              html: false
          },
          {
              label: "Employee Name",
              field: "name",
              numeric: false,
              html: false
          },
          // {
          //     label: "Image",
          //     field: "image",
          //     numeric: false,
          //     html: '<img src="image">',
          // },
          {
              label: "Department/Designation",
              field: "dep_design",
              numeric: false,
              html: '<br />'
          },
          {
              label: "Amount",
              field: "amount",
              numeric: false,
              html: '<br />'
          },
          {
              label: "Payment Type",
              field: "type",
              numeric: false,
              html: '<br />'
          },
          {
              label: "Advance",
              field: "advance",
              numeric: false,
              html: '<br />'
          },
          {
              label: "Due",
              field: "due",
              numeric: false,
              html: '<br />'
          },
      ],

  }),
  components: {
      "datatable": DataTable
  },

  async created() {
      this.getPayments();
  },
  computed: {
      ...mapState('payment', ['payments']),
      finalPayments() {
          return this.payments.length > 0 ? this.payments.map((payment,sl)=>({
              ...payment,sno: sl + 1,
              employee_id: (payment.employee ? payment.employee.employee_id : ''),
              name: (payment.employee ? payment.employee.name : ''),
              // image: '<img class="img-custom" src="'+(employee.image? employee.image : require('@/assets/backend/img/user-image.png'))+'" >',
              dep_design: 'Department: '+(payment.employee ? (payment.employee.department ? payment.employee.department.name : '') : '')+'<br />'+ 'Designation: '+(payment.employee ?  (payment.employee.designation ? payment.employee.designation.name : ''): ''),
          })) : [];
      }
  },

  methods: {
      ...mapActions('payment', ['getPayments']),
      viewItem(props)
  {
    this.$router.push({ name:'edit-payment', 
              params: { id: props.id}}).catch( () => {} 
          );
  },

      printDiv(divID) {
              var divContents = document.getElementById("print").innerHTML;
              var a = window.open('', '', '');
              a.document.write('<html><head><style type="text/css">');
              // a.document.write('<link rel="stylesheet" href="/assets/backend/css/bootstrap.min.css" type="text/css" />');
              a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
              a.document.write('.material-table td,.material-table th {border: 1px solid #e3ebf3; }');
              a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
              a.document.write('table{border-collapse:collapse; margin:0 auto;}');
              a.document.write('table th:last-child, table td:last-child{display:none !important}');
              a.document.write('.table-header{display:none !important}');
              a.document.write('.table-footer{display:none !important}');
              a.document.write('#search-input-container{display:none !important}');
              a.document.write('tbody, td, tfoot, th, thead, tr {border: 1px solid #e3ebf3 !important; text-align: center;}');
              a.document.write('thead th{font-size:13px!important;}');
              a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
              a.document.write('table td, img {    height: 70px !important; width :70px !important}');
              a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
              a.document.write('h3,h1,h2,p{text-align:center;}');
              a.document.write('</style></head><body> <h1>Jamalpur Central Hospital Ltd</h1><h2>Amlapara, Jamalpur</h2><h3>All Employee Attendance Report</h3></h3>');
              a.document.write(divContents);
              a.document.write('<p class="bottom-line"> This report is system generated, no signiture needed </p>');
              a.document.write('</body></html>');
              a.document.close();
              a.focus();
              a.print();
              // document.body.innerHTML = oldPage;
          }
  },
};
</script>

<style>
  /* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
  .leave_font {
      font-weight: bold;
  }
</style>
