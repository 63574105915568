<template>
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/job_card">Job Card</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Attendance Job Card </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="content-body  mb-4">
            <v-form class="g-3" ref="jobcardform" @submit="getJobCard" v-model="valid" lazy-validation>
                <div class="add-contact">
                    <div class="row">
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2"> Month <span class="text-danger">*</span></label>
                                <v-select v-model="salary.month" :rules="monthRules" id="" :items="months" outlined flat solo dense :item-text="item=>item.name" :item-value="item=>item.id"></v-select>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3">
                            <div class="form-group">
                                <label for="" class="mb-2">Year <span class="text-danger">*</span></label>
                                <v-select :rules="yearRules" v-model="salary.year" id="" :items="years" outlined flat solo dense :item-text="item=>item" :item-value="item=>item"></v-select>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-4">
                            <div class="form-group">
                                <label for="" class="mb-2">Employee <span class="text-danger">*</span></label>
                                <v-autocomplete :rules="employeeRules" v-model="salary.employee_id" id="" :items="finalEmployees" outlined flat solo dense :item-text="item=>item.name+'-'+item.employee_id" :item-value="item=>item.employee_id"></v-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-2 col-lg-1">
                            <v-btn :loading="loading" type="submit" class="custom-btn btn-sm btn text-white">Submit</v-btn>
                        </div>
                    </div>
                </div>
            </v-form>
            <div v-if="jobcards.length">
                <div class="row">
                    <div class="float-right text-right">
                        <button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-print"></i></span> Print</button>
                        <button @click="downloadFile()" class="btn btn-sm custom-btn mb-3 ml-2"><span><i class="fa fa-print"></i></span> Export</button>
                    </div>
                </div>
                <div class="table-responsive card mt-4">
                <div class="table-header" id="print">
                    <table class="material-table mt-4 table">
                        <thead>
                            <tr class="text-center">
                                <th>Date</th>
                                <th>Shift</th>
                                <th>In Time</th>
                                <th>Out Time</th>
                                <th>Late Minutes</th>
                                <th>OT Hours</th>
                                <th>Day Status</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody v-if="jobcards.length">
                            <tr class="text-center" v-for="(jobcard, index) in jobcards" :key="index">
                                <td> {{ jobcard.date ? moment(jobcard.date).format("DD MMMM YYYY") : '' }} </td>
                                <td> {{ jobcard.shift_data ? moment(jobcard.shift_data, "HH:mm").format("DD MMMM YYYY - hh:mm A") : '-' }} </td>
                                <td> {{ jobcard.in_time ? moment(jobcard.in_time, "HH:mm").format("hh:mm A") : '-' }} </td>
                                <td> {{ jobcard.out_time ? moment(jobcard.out_time, "HH:mm").format("hh:mm A") : '-' }} </td>
                                <td> {{ jobcard.late_time ? jobcard.late_time : '-' }} </td>
                                <td> {{ jobcard.ot_hours ? ot_hours : '-' }} </td>
                                <td v-if="jobcard.day_status == 'Present'"> <span class="text-success">{{ jobcard.day_status }}</span> </td>
                                <td v-else-if="jobcard.day_status == 'Absent'"> <span class="text-danger">{{ jobcard.day_status }}</span> </td>
                                <td v-else-if="jobcard.day_status == 'Weekly Holiday'"> <span class="text-primary">{{ jobcard.day_status }}</span> </td>
                                <td v-else-if="jobcard.day_status == 'Holiday'"> <span class="text-primary">{{ jobcard.day_status }}</span> </td>
                                <td v-else> - </td>
                                <td> {{ jobcard.remarks }} </td>
                            </tr>
                            <tr class="text-center">
                                <td colspan="8" class="text-white">-</td>
                            </tr>
                            <tr class="text-center">
                                <td colspan="4">Roaster Hours</td>
                                <td colspan="4"> {{ roaster_hours }} </td>
                            </tr>
                            <tr class="text-center">
                                <td colspan="4">Working Hours</td>
                                <td colspan="4"> {{ working_hours }} </td>
                            </tr>
                            <tr class="text-center">
                                <td colspan="4">Net Pay</td>
                                <td colspan="4"> {{ net_pay }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import exportFromJSON from "export-from-json";
import { mapActions, mapState } from "vuex";
import DataTable from "vue-materialize-datatable";
import moment from "moment";

export default {
    name: 'JobCard',
    data: () => ({
        date : null,
        jobcards: [],
        roaster_hours: [],
        working_hours: [],
        net_pay: [],
        fixedSuccess: false,
        fixedError: false,
        valid: true,
        year : null,
        loading: false,
        isValidate: false,
        month : null,
        reports: [],
        error:"",
        salary: {
            employee_id: '',
            month: '',
            year: ''
        },
        months : [
            {id: 1, value: 1, name: 'January'}, 
            {id: 2, value: 2, name: 'February'}, 
            {id: 3, value: 3, name: 'March'}, 
            {id: 4, value: 4, name: 'April'}, 
            {id: 5, value: 5, name: 'May'}, 
            {id: 6, value: 6, name: 'June'}, 
            {id: 7, value: 7, name: 'July'}, 
            {id: 8, value: 8, name: 'August'}, 
            {id: 9, value: 9, name: 'September'}, 
            {id: 10, value: 10, name: 'October'}, 
            {id: 11, value: 11, name: 'November'}, 
            {id: 12, value: 12, name: 'December'}, 
        ],
        years: [],
        monthRules: [
            (v) => !!v || "Month is required",
        ],
        yearRules: [
            (v) => !!v || "Year is required",
        ],
        employeeRules: [
            (v) => !!v || "Select Employee",
        ],

        temp_month: '',
        temp_year: ''
    }),
    components: {
        "datatable": DataTable
    },

    async created() {
        this.getEmployees();
        this.setYear();
    },
    computed: {
        ...mapState('employee',['employees']),
        finalEmployees() {
            var employees = this.employees 
            return employees
        }
    },

    methods: {
        ...mapActions('employee',['getEmployees']),
        setYear(){
            var currentYear = new Date().getFullYear(),
            startYear = startYear || 2000;  
            while ( currentYear >= startYear ) {
                this.years.push(currentYear--);
            }
        },
        downloadFile() {
                const data = this.jobcards.map((jobcard,sl)=>{
                    return {
                        date: jobcard.date ? moment(jobcard.date).format("DD MMMM YYYY") : '',
                        shift: jobcard.shift_data ? moment(jobcard.shift_data, "HH:mm").format("DD MMMM YYYY - hh:mm A") : '-',
                        in_time: jobcard.in_time ? moment(jobcard.in_time, "HH:mm").format("hh:mm A") : '-',
                        out_time: jobcard.out_time ? moment(jobcard.out_time, "HH:mm").format("hh:mm A") : '-',
                        late_time: jobcard.late_time ? jobcard.late_time : '-',
                        day_status: jobcard.day_status,
                        remarks:jobcard.remarks
                    }
                });
                const fileName = "Job Card";
                const exportType = exportFromJSON.types.csv;

                if (data) exportFromJSON({ data, fileName, exportType });
            },
        getJobCard(event){
            event.preventDefault();
            this.loading = true
            let isValidate = true
            this.error = "";
            this.$refs.jobcardform.validate();
            this.$refs.jobcardform.inputs.forEach((input) => {
                if (input.hasError) {
                    isValidate = false;
                }
            });
            if (!isValidate) {
                this.loading = false
                return;
            }
            let month_name = this.months.find((mon)=> mon.id === this.salary.month);
            this.temp_month = month_name.name
            let year_name = this.years.find((ye)=> ye === this.salary.year);
            this.temp_year = year_name
            this.$store.dispatch("attendance/getJobCard",this.salary).then((response)=>{
                if(response.data){
                    this.loading = false
                    this.fixedSuccess = response.message
                    this.jobcards = response.data;
                    this.roaster_hours = response.roaster_hours;
                    this.working_hours = response.working_hours;
                    this.net_pay = response.net_pay;
                }
                else{
                    this.loading = false
                    this.fixedError = response.message
                }
            }).catch(()=>{})
        }, 
        printDiv(divID) {
            var divContents = document.getElementById("print").innerHTML;
            var a = window.open('', '', '');
            a.document.write('<html><head><style type="text/css">');
            a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
            a.document.write('.material-table td,.material-table th {border: 1px solid #e3ebf3; }');
            a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
            a.document.write('table{border-collapse:collapse; margin:0 auto;}');
            a.document.write('thead th{font-size:13px!important;}');
            a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
            a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
            a.document.write('h3,h2,h1,p{text-align:center;}');
            a.document.write('</style></head><body> <h1>Jamalpur Central Hospital Ltd</h1><h2>Amlapara, Jamalpur</h2><h3>Salary Sheet </h3>');
            a.document.write('<p>'+this.temp_month + ' '+ this.temp_year +'</p>');
            a.document.write(divContents);
            a.document.write('<p> This report is system generated, no signiture needed </p>');
            a.document.write('</body></html>');
            a.document.close();
            a.focus();
            a.print();
            
            location.reload(this.$router.push("/job_card").catch(() => {}));
        }
    },
};
</script>

<style>

</style>
