<template>
<div class="container-wrappero">
    <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb content_shadow">
                    <li class="breadcrumb-item">
                        <router-link to="/dashboard">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Attendence Sheet </li>
                </ol>
            </nav>
        </div>
    </div>
    <v-alert class="mt-4" dense text type="success" dismissible v-if="fixedSuccess">
        {{fixedSuccess}}
    </v-alert>
    <v-alert class="mt-4" dense text type="error" dismissible v-if="fixedError">
        {{fixedError}}
    </v-alert>
    <div class="content-body  mb-4">
        <v-form class="g-3" ref="salaryreportform" @submit="attendenceReport" v-model="valid" lazy-validation>
            <div class="add-contact">
                <div class="row">
                    <div class="col-md-3 col-lg-3">
                        <div class="form-group">
                            <label for="" class="mb-2"> From Date
                            </label>
                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :rules="fromDateRules" v-model="attendence.from_date" label="Select from date" readonly v-bind="attrs" prepend-inner-icon="mdi-calendar" prepend-icon="" ref="fromdate" @click:prepend-inner="$refs.fromdate.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                </template>
                                <v-date-picker v-model="attendence.from_date" @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <div class="form-group">
                            <label for="" class="mb-2"> To Date
                            </label>
                            <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :rules="toDateRules" v-model="attendence.to_date" label="Select to date" readonly v-bind="attrs" prepend-inner-icon="mdi-calendar" prepend-icon="" ref="todate" @click:prepend-inner="$refs.todate.$el.querySelector('input').click()" v-on="on" solo flat outlined dense></v-text-field>
                                </template>
                                <v-date-picker v-model="attendence.to_date" @input="menu3 = false"></v-date-picker>
                            </v-menu>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        <div class="form-group">
                            <label for="" class="mb-2"> Employee List
                            </label>
                            <v-autocomplete :items="finalEmployees" v-model="attendence.employee_id" :item-text="item=>item.name+'-'+item.employee_id" :rules="employeeRules" :item-value="item=>item.employee_id" label="Select Employee" solo flat outlined dense></v-autocomplete>
                        </div>
                    </div>

                    <div class="col-md-2 col-lg-1">
                        <v-btn :loading="loading" type="submit" class="custom-btn btn-sm btn text-white">Submit</v-btn>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="table-responsive card mt-4" v-if="attendences">
            <div class="row">
                <div class="float-right text-right">
                    <button @click="downloadFile()" class="btn btn-sm custom-btn mb-3 mr-2"><span><i class="fa fa-print"></i></span> Export</button>
                    <button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3"><span><i class="fa fa-print"></i></span> Print</button>
                </div>
            </div>
            <div class="table-header" id="print">
                <table class="material-table mt-4 table"> 
                    <thead>
                        <tr class="text-center">
                            <th>Sl</th>
                            <th>Employee Id</th>
                            <th>Employee Name</th>
                            <th>Date</th>
                            <th>WH policy</th>
                            <th>Check In</th>
                            <th>Check Out</th>
                            <th>Late Time</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="attendences">
                        <tr class="text-center" v-for="(attendence,index) in attendences" :key="index">
                            <td> {{index + 1}} </td>
                            <td> {{attendence.employee_id }}</td>
                            <td> {{ attendence.employee_name }}</td>
                            <td> {{ attendence.date ? moment(attendence.date).format("MMMM Do YYYY") : '-' }}</td>
                            <td> {{ attendence.roaster_start ? moment(attendence.roaster_start, "HH:mm").format("hh:mm A") : '-' }} - {{ attendence.roaster_end ? moment(attendence.roaster_end, "HH:mm").format("hh:mm A") : '-' }} </td>
                            <td> {{ attendence.start_time ? moment(attendence.start_time, "HH:mm").format("hh:mm A") : '-' }}</td>
                            <td> {{ attendence.end_time && attendence.start_time != attendence.end_time ? moment(attendence.end_time, "HH:mm").format("hh:mm A"): '-' }}</td>
                            <td> {{ attendence.late ? moment(attendence.late, "HH:mm").format("H") + ' Hour ' + moment(attendence.late, "HH:mm").format("mm") + ' minutes': '-' }}</td>
                            <td>
                                <p class="text-primary" v-if="attendence.status == 'P'">Present</p>
                                <p class="text-danger" v-if="attendence.status == 'A'">Absent</p>
                            </td>

                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text-center">
                            <td colspan="8">
                                No data Available
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import DataTable from "vue-materialize-datatable";
import moment from 'moment';
import exportFromJSON from "export-from-json";
export default {
    name: 'dashboard',
    data: () => ({

        // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        // menu: false,
        // modal: false,
        menu2: false,
        menu3: false,
        attendence: {
            from_date: null,
            to_date: null,
            employee_id: null,
        },

        date: null,
        temp_attrs: [],
        attendences: [],
        valid: true,
        year: null,
        loading: false,
        isValidate: false,
        month: null,
        fixedSuccess: false,
        fixedError: false,
        attrs: [],
        reports: [],
        error: "",

        fromDateRules: [
            (v) => !!v || "From date is required",
        ],
        toDateRules: [
            (v) => !!v || "To date is required",
        ],
        employeeRules: [
            (v) => !!v || "Select Employee",
        ],
    }),
    components: {
        "datatable": DataTable
    },

    async created() {
        this.getEmployees();
        this.todayReport();
    },
    computed: {
        ...mapState('employee', ['employees']),
        finalEmployees() {
            var employees = this.employees
            employees.unshift({
                employee_id: 'all',
                name: 'All'
            })
            return employees
        }
    },

    methods: {
        ...mapActions('employee', ['getEmployees']),
        todayReport() {
            this.attendence.employee_id = 'all';
            this.attendence.from_date = moment(new Date()).format("YYYY-MM-DD")
            this.attendence.to_date = moment(new Date()).format("YYYY-MM-DD")
            this.submitAttendance(1);
        },
        downloadFile() {
            const data = this.attendences.map((attendance, sl) => {
                return {
                    Sl: sl,
                    employee_id: attendance.employee_id,
                    employee_name: attendance.employee_name,
                    date: attendance.date ? moment(attendance.date).format("MMMM Do YYYY") : '-',
                    start_time: attendance.start_time ? moment(attendance.start_time, "HH:mm").format("hh:mm A") : '-',
                    end_time: attendance.end_time ? moment(attendance.end_time, "HH:mm").format("hh:mm A") : '-',
                    status: attendance.status == 'A' ? 'Absent' : 'Present'
                }
            });
            const fileName = "Attendance Report";
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({
                data,
                fileName,
                exportType
            });
        },
        attendenceReport(event) {
            event.preventDefault();
            this.loading = true
            let isValidate = true
            this.error = "";
            this.$refs.salaryreportform.validate();
            this.$refs.salaryreportform.inputs.forEach((input) => {
                if (input.hasError) {
                    isValidate = false;
                }
            });
            if (!isValidate) {
                this.loading = false
                return;
            }
            this.submitAttendance();

        },
        submitAttendance(number = null) {
            this.$store.dispatch("attendance/AttendencReport", this.attendence).then((response) => {
                // this.$router.push("/employee").catch(()=>{})
                if (response.data) {
                    this.loading = false,
                    this.fixedSuccess = (number == 1) ?  false : response.message;
                    this.attendences = response.data;
                } else {
                    this.loading = false
                    this.fixedError = response.message
                }
            }).catch(() => {
                this.loading = false
            })
        },

        printDiv(divID) {
            var divContents = document.getElementById("print").innerHTML;
            var a = window.open('', '', '');
            a.document.write('<html><head><style type="text/css">');
            // a.document.write('<link rel="stylesheet" href="/assets/backend/css/bootstrap.min.css" type="text/css" />');
            a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
            a.document.write('.material-table td,.material-table th {border: 1px solid #e3ebf3; }');
            a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
            a.document.write('table{border-collapse:collapse; margin:0 auto;}');
            a.document.write('thead th{font-size:13px!important;}');
            a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
            a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
            a.document.write('h3,h2,h1,p{text-align:center;}');
            a.document.write('</style></head><body> <h1>Jamalpur Central Hospital Ltd</h1><h2>Amlapara, Jamalpur</h2><h3>Attendence Sheet </h3>');
            a.document.write('<p>From date:- '+ moment(this.attendence.from_date).format("DD MM YYYY") + ' To date:- '+ moment(this.attendence.to_date).format("DD MM YYYY") +'</p>');
            a.document.write(divContents);
            a.document.write('<p class="bottom-line"> This report is system generated, no signiture needed </p>');
            a.document.write('</body></html>');
            a.document.close();
            a.print();
            // document.body.innerHTML = oldPage;
            // location.reload(this.$router.push("/attendance_report").catch(() => {}));
        }
    },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
</style>
