<template>
  <div class="container-fluid">
    <div class="container-wrapper">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/leave">Invoice</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">List</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <div class="row">
        
      </div>
      <div class="table-responsive">
        <datatable
          class="mt-2"
          title="Invoices"
          :columns="tableColumns1"
          :rows="finalInvoices"
          :printable="false"
        >
          <th slot="thead-tr">Actions</th>
          <template slot="tbody-tr" scope="props">
            <td>
              <button
                class="data-btn mr-2"
                @click="(e) => editItem(props.row, e)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button class="data-btn" @click="(e) => deleteItem(props.row, e)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </template>
        </datatable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTable from "vue-materialize-datatable";
export default {
  name: "Dashboard",

  data: () => ({
    tableColumns1: [
      {
        label: "Sl",
        field: "sno",
        numeric: false,
        html: false,
      },
      {
        label: "Invoice ID",
        field: "invoice_id",
        numeric: false,
        html: false,
      },
      {
        label: "Amount Due",
        field: "amount",
        numeric: false,
        html: false,
      },
      {
        label: "Sub Total",
        field: "sub_total",
        numeric: false,
        html: false,
      },
      {
        label: "Total",
        field: "total",
        numeric: false,
        html: false,
      },
      {
        label: "Status",
        field: "status",
        numeric: false,
        html: '<span>',
      },
      
    ],
   
  }),
  components: {
    datatable: DataTable,
  },

  async created() {
    this.getInvoices();
  },
  computed: {
    ...mapState("payment", ["invoices"]),
    finalInvoices() {
      return this.invoices.length > 0
        ? this.invoices.map((invoice, sl) => ({
            ...invoice,
            sno: sl + 1,
            amount: invoice.details.length ? invoice.details[0].AmountDue : "",
            sub_total: invoice.details.length ? invoice.details[0].SubTotal : "",
            total: invoice.details.length ? invoice.details[0].Total : "",
            status: '<span class="">'+invoice.details.length ? invoice.details[0].Status : ""+'</span>'
          }))
        : [];
    },
  },

  methods: {
    ...mapActions("payment", ["getInvoices"]),
    AddLeave() {
      this.$router.push("/add_leave").catch(() => {});
    },
    editItem(props, event) {
      console.log("props data peyechi", props, event);
      this.$router
        .push({ name: "ViewInvoice", params: { id: props.id } })
        .catch(() => {});
    },
    deleteItem(props, e) {
      this.$swal({
        title: "Delete Leave Appliction",
        text: "Are you sure you want to delete this Leave application?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Delete!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed

          axios
            .delete("leave_application/" + props.id)
            .then((res) => {
              // this.feedlist = res.data;
              this.$swal("Successfully deleted");
              this.getLeaves();
            })
            .catch((err) => {
              console.log("Error", err);
            });
        }
      });
    },
  },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
.data-btn {
  background-color: #607d8b !important;
  color: #fff;
  padding: 5px 10px 5px 8px !important;
}
.custom-btn {
  background-color: #607d8b !important;
}
</style>
