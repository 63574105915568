export default {
    namespaced: true,
  
    state: () => ({
        attendances: [],
        employeeforattendances: [],
        holidays: [],
        attendancebyid: [],
    }),
  
    getters: {
        attendances(state){
            return state.attendances
      },
      employeeforattendances(state){
        return state.employeeforattendances
      },
      holidays(state){
        return state.holidays
      },
      attendancebyid(state){
        return state.attendancebyid
      },
    },
  
    mutations: {
      populateAttendance(state, newAttendance){
        state.attendances = newAttendance;
      },
      saveAttendance(state,newAttendance)
      {
        state.attendances.push(newAttendance);
      },
      postAttendance(){

      },
      populateEmployeeforAttendance(state, newEmployeeforAttendance){
        state.employeeforattendances = newEmployeeforAttendance;
      },
      populateHoliday(state, newHoliday){
        state.holidays = newHoliday;
      },
      populateattendancebyid(state, newattendancebyid){
        state.attendancebyid = newattendancebyid;
      },
    },
  
    actions: {
      async getEmployeeforAttendances({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("employeeforattendance")
          .then(res => {
            resolve(res);
            commit("populateEmployeeforAttendance",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async getHolidays({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("get-holiday")
          .then(res => {
            resolve(res);
            commit("populateHoliday",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async getAttendancebyID({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("employeeforattendance/"+id)
          .then(res => {
            resolve(res);
            console.log(res);
            commit("populateattendancebyid",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      // async getAttendancebyID(ctx,apiArg){
      //   let isSuccess = false
      //   await axios.get("getAttendancebyID/"+apiArg)
      //   .then(response => {
      //     isSuccess = response.data.data
      //     return isSuccess
      //   }).catch(error => {
      //     isSuccess = false;
      //   })
      //   return isSuccess;
      // },



      getAttendances({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("attendance")
          .then(res => {
            resolve(res);
            commit("populateAttendance", res.data.data);
          })
          .catch(error => {
            reject(error)
          })
        });
      },

      async storeAttendance({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios.post('attendance', data)
            .then((result) => {
              resolve(result);
              commit("saveAttendance", result.data.data);
            })
            .catch((error) => {
              reject(error)
            });
        });
      },
      async getAttendanceEmployee({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios.post('attendance_employee', data)
            .then((result) => {
              resolve(result);
              commit("saveAttendance", result.data.data);
            })
            .catch((error) => {
              reject(error)
            });
        });
      },
      async saveAttendance({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios.post('save_attendance', data)
            .then((result) => {
              resolve(result);
              commit("postAttendance", result.data.data);
            })
            .catch((error) => {
              reject(error)
            });
        });
      },
      async getAttendanceReport(ctx, data) {
        let isSuccess = false
        await axios.post('get_attendance_data', data).then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async getEmployeeLeaves(ctx, data) {
        let isSuccess = false
        await axios.post('get_employee_attendance_leaves', data).then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },

      async getEmployeeAttendance(ctx, data) {
        let isSuccess = false
        await axios.post('get_employee_attendance', data).then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },

      async AttendencReport(ctx, data){
        let isSuccess = false
        await  axios.post('get_attendence_report', data).then(response => {
          isSuccess = response.data
          console.log("sadkfjhskhfkhsdfkjh",response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async getJobCard({ commit },apiArg){
          let isSuccess = false
          await axios.post("attendanace_job_card",apiArg).then(response => {
            isSuccess = response.data
            return isSuccess
          })
            .catch(error => {
                isSuccess = false;
                console.log(error)
            })
            return isSuccess;
        }
    }
  };