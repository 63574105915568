<template>
  <div class="container-fluid">
    <div class="container-wrappero">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add Schedule
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- content-page-header end -->
      <v-alert class="mt-4" dense text type="success" dismissible v-if="fixedSuccess">
                        {{fixedSuccess}}
            </v-alert>
            <v-alert class="mt-4" dense text type="error" dismissible v-if="fixedError">
                        {{fixedError}}
            </v-alert>
      <div class="content-body mb-4">
        <v-form
          @submit="updateAttendanceSchedule"
          v-model="valid"
          ref="attendancescheduleform"
          lazy-validation
        >
          <div class="add-contact row">
            <div class="col-md-12">
              <div class="accordion accordion-main" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Schedule Information
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row g-3">
                        
                        <div class="col-md-6 padding mt-2">
                          <div class="custom-group">
                            <label class="form-label">Start Time *</label>
                            <v-text-field
                              placeholder="Enter start time"
                              type="time"
                              v-model="schedule.start_time"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-6 padding mt-2">
                          <div class="custom-group">
                            <label class="form-label">End Time *</label>
                            <v-text-field
                              placeholder="Enter end time"
                              type="time"
                              v-model="schedule.end_time"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="submit-section">
              <div class="">
                <v-btn
                  class="mr-4 custom-btn"
                  type="button"
                  @click="resetButton()"
                  color="error"
                >
                  Reset
                </v-btn>
              </div>
              <div class="">
                <v-btn
                  class="custom-btn f-right"
                  color="blue darken-4"
                  type="submit"
                  :loading="loading"
                  dark
                >
                  Submit
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Schedule",

  data: () => ({
    schedule: {
      id: "",
      start_time: "",
      end_time: ""
    },
    main_image: [],
    emp_image: null,
    loading: false,
    valid: true,
    fixedSuccess: false,
    fixedError: false,
    error: "",
    previewImageValidation: "",
   
    requiredRules: [(v) => !!v || "Field is required"],
  }),
  async created() {
    this.getAttendanceSchedule();
  },
  computed: {

  },
  methods: {
    updateAttendanceSchedule(event) {
      event.preventDefault();
      this.loading = true;
      let isValidate = true;
      this.error = "";
      this.$refs.attendancescheduleform.validate();
      this.$refs.attendancescheduleform.inputs.forEach((input) => {
        if (input.hasError) {
          isValidate = false;
        }
      });
      if (!isValidate) {
        this.loading = false;
        return;
      }
      if (this.schedule.id == "") {
        this.$store.dispatch("schedule/saveAttendanceSchedule", this.schedule)
          .then((response) => {
            this.loading = false
            if(response.data.data){
              this.fixedSuccess = response.data.message
            }
            else{
              this.fixedError = response.data.message
            }
              this.getAttendanceSchedule();
            })
          .catch(() => {});
      } else {
        this.$store.dispatch("schedule/UpdateAttendanceSchedule", this.schedule)
          .then((response) => {
            this.loading = false
            if(response.data.data){
              this.fixedSuccess = response.data.message
            }
            else{
              this.fixedError = response.data.message
            }
            this.getAttendanceSchedule();
          })
          .catch(() => {});
      }
    },
    
    getAttendanceSchedule() {
      this.$store
        .dispatch("schedule/getAttendanceSchedule", this.$route.params.id)
        .then((res) => {
          if (res) {
            (this.schedule.id = res.id),
              (this.schedule.start_time = res.start_time),
              (this.schedule.end_time = res.end_time)
          }
        });
    },

    resetButton() {
      this.$refs.attendancescheduleform.reset();
    },
  },
};
</script>
    <style>
.accordion-main {
  border: 1px solid #ddd;
  margin-right: 2px;
  padding: 10px;
}
.add-contact .accordion-item {
  margin-bottom: 0px;
}
.company-image {
  width: 200px;
  height: 200px;
  text-align: center;
  margin: 0 auto;
}
.company-image img {
  height: 100%;
  object-fit: cover;
}
.submit-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
    