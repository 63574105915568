export default {
    namespaced: true,
  
    state: () => ({
        settings: [],
    }),
  
    getters: {
      settings(state){
        return state.settings
      },
      
    },
  
    mutations: {
      populateSetting(state, newSetting){
        state.settings = newSetting;
      },

      updateSetting(state, newUpdateSetting){

      }
  
    },
  
    actions: {
      async getSalarySetting({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("salary-setting")
          .then(res => {
            console.log("Salary  Data::: ",res.data.data)
            resolve(res);
            commit("populateSetting",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
     
      
      async AddSalarySetting({ commit },data){
        return new Promise((resolve, reject) => {
            axios
              .post('salary-setting', data)
              .then((result) => {
                resolve(result);
              })
              .catch((error) => {
                console.log(error.response.data.errors);
                reject(error);
              });
          });
      },

      async UpdateSalarySetting({ commit }, apiArg){
        return new Promise((resolve, reject) => {
            axios.put("salary-setting/"+apiArg.id,apiArg)
            .then(res => {
              console.log("Department Data::: ",res.data.data)
              resolve(res);
              commit("updateSetting",res.data.data);
            })
            .catch(error => {
              reject(error)
                  console.log("Something Error")
              })
            });
      },

      async Settingdetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("salary-setting/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },
     
      
      
    }
  };