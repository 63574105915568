import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import department from "./department"
import designation from "./designation"
import leave from "./leave"
import miscellaneous from "./miscellaneous"
import employee from "./employee"
import useradmin from "./useradmin"
import attendance from "./attendance"
import holiday from "./holiday"
import salary_setting from "./salary_setting"
import roaster from "./roaster"
import adminlayout from "./adminlayout"
import axios from "axios";
import salary from './salary';
import provident from './provident';
import payment from './payment';
import schedule from './schedule';
import company_settings from './company_settings';
window.axios = axios;

Vue.use(Vuex)
axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('auth_token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }

    return config;
  }, 

  (error) => {
    return Promise.reject(error);
  }
);
export default new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    modules: {
      department: department,
      designation: designation,
      leave: leave,
      miscellaneous: miscellaneous,
      employee: employee,
      company_settings: company_settings,
      useradmin: useradmin,
      attendance: attendance,
      holiday: holiday,
      roaster: roaster,
      adminlayout: adminlayout,
      salary: salary,
      provident: provident,
      payment: payment,
      schedule: schedule,
      salary_setting: salary_setting
    },
    plugins: [createPersistedState()]
  });