<template>
<div class="container-fluid">
    <div class="container-wrappero">
        <div class="content-page-header mb-4">
            <div class="breadcrumb-header">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb content_shadow">
                        <li class="breadcrumb-item">
                            <router-link to="/attendance">Attendance</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add Attendance</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="content-body  mb-4">
            <v-alert dense text type="success" dismissible v-if="fixedSuccess">
                {{fixedSuccess}}
            </v-alert>
            <div class="add-contact">
                <v-form class="g-3" @submit.prevent="getAttendance()" v-model="valid" ref="attendanceform">
                    <div class="row">
                        <div class="col-md-5 col-lg-3 padding">
                            <label class="form-label">Date <span class="text-danger">*</span></label>
                            <v-text-field type="date" :rules="dateRules" v-model="attendance.date" outlined dense></v-text-field>
                        </div>
                        <div class="col-md-5 col-lg-3 padding">
                            <label class="form-label">Select Employee <span class="text-danger">*</span></label>
                            <v-autocomplete :rules="employeeRules" v-model="attendance.employee_id" :items="finalEmployees" outlined flat solo dense :item-text="item=>item.name" :item-value="item=>item.id"></v-autocomplete>
                        </div>
                  
                        <div class="col-md-2 col-lg-2 padding">
                            <v-btn color="blue darken-4" class="custom-btn btn-sm btn text-white" type="submit" :loading="loading" dark>
                                Submit
                            </v-btn>
                        </div>
                       
                    </div>
                </v-form>
            </div>
            <div class="card" v-if="attendee.length">
                <v-form class="g-3" @submit.prevent="saveAttendance()" v-model="valid" ref="attendancesaveform">
                    <div class="card-header">
                        Mark Attendance
                    </div>
                    <div class="card-body">
                        <table class="table no-border w-100">
                            <thead>
                                <tr>
                                    <th>Employee Id</th>
                                    <th>Name</th>
                                    <th>Roaster Time</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(attend, index) in attendee" :key="index">
                                    <td>{{attend.employee ? attend.employee.employee_id : ''}}</td>
                                    <td>{{attend.employee ? attend.employee.name : ''}}</td>
                                    <td>{{moment(attend.start_time, "HH:mm").format("hh:mm A") +' - '+ moment(attend.end_time, "HH:mm").format("hh:mm A")}}</td>
                                    <td>
                                        <input type="hidden" v-model="make_attendance[index].roaster_id">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <v-switch
                                            v-model="make_attendance[index].status"
                                            class="mt-0"
                                            inset
                                            @change="setAttendance"
                                           :value="'P'"
                                            ></v-switch>
                                        </div>
                                        <!-- <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate bootstrap-switch-id-checkbox123456 bootstrap-switch-off"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">P</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-danger">A</span>
                                            <input type="checkbox" class="make-switch" name="checkbox" v-model="switch1" data-on-color="success" data-on-text="P" data-off-text="A" data-off-color="danger"></div>
                                        </div> -->
                                        
                                            <div class="col-md-5 my-0 py-0" v-if="make_attendance[index].status != 'P'">
                                                <label for="inputEmail4" class="form-label"
                                                >Leave Type *</label
                                                >
                                                <v-select
                                                :items="leave_type_items"
                                                v-model="make_attendance[index].leave_type"
                                                :item-text="(item) => item.name"
                                                :item-value="(item) => item.value"
                                                :rules="requiredRules"
                                                outlined
                                                dense
                                                ></v-select>
                                            </div>
                                            <div class="col-md-5 my-0 py-0" v-if="make_attendance[index].status != 'P'">
                                                <label class="form-label">Reason *</label>
                                                <v-textarea
                                                placeholder="Enter Leave Reason"
                                                type="text"
                                                rows="1"
                                                v-model="make_attendance[index].reason"
                                                :rules="requiredRules"
                                                outlined
                                                dense
                                                ></v-textarea>
                                            </div>
                                            <div class="col-md-5" v-if="make_attendance[index].status == 'P'">
                            <label for="Start time" class="form-label"
                              >Start Time
                            </label>
                            <v-text-field
                              placeholder="Enter start time"
                              label=""
                              type="time"
                              v-model="make_attendance[index].start_time"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                          <div class="col-md-5" v-if="make_attendance[index].status == 'P'">
                            <label for="End time" class="form-label"
                              >End Time
                            </label>
                            <v-text-field
                              placeholder="Enter end time"
                              label=""
                              type="time"
                              v-model="make_attendance[index].end_time"
                              :rules="requiredRules"
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <v-btn type="submit" :loading="loading" class="custom-btn">
                            Submit
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: 'addAttendance',
    data: () => ({
        valid: true,
        fixedSuccess: false,
        loading: false,
        switch1:[],
        leave_type: [],
        attendee: [],
        start_time:[],
        reason:[],
        end_time:[],
        attendance: {
            employee_id: "",
            roaster_id: "",
            date: "",
            start_time: "",
            end_time: "",
        },
        make_attendance: [],
        leave_type_items: [
            { id: 0, name: "Sick", value: "sick" },
            { id: 1, name: "Casual", value: "casual" },
            // { id: 2, name: "half day", value: "half day" },
            { id: 3, name: "Maternity", value: "maternity" },
            { id: 4, name: "Unpaid", value: "unpaid" },
            { id: 5, name: "Other", value: "other" },
        ],
        requiredRules: [(v) => !!v || "Field is required"],
        employeeRules: [
            v => !!v || 'Employee is required',
        ],
        roasterRules: [
            v => !!v || 'Roaster is required',
        ],
        dateRules: [
            v => !!v || 'Date is required',
        ],
        startTimeRules: [
            v => !!v || 'Start Time is required',
        ],
        endTimeRules: [
            v => !!v || 'End Time is required',
        ],
    }),
    async created() {
        this.getEmployees();
        this.getRoasters();
    },
    computed: {
        ...mapState('employee', ['employees']),
        ...mapState('roaster', ['roasters']),
        finalEmployees() {
            var employees = this.employees
            employees.unshift({
                    id: 'all',
                    name: 'All'
                })  
                return employees
        }
    },
    methods: {
        ...mapActions('employee', ['getEmployees']),
        ...mapActions('roaster', ['getRoasters']),
        resetButton(){
            this.$refs.attendanceform.reset();
        },
        setAttendance()
        {
            console.log("checked item peyechi", this.make_attendance)
        },
        getAttendance() {
            console.log("attendance form submit...")
            if (this.$refs.attendanceform.validate()) {
                this.$store.dispatch("attendance/getAttendanceEmployee", this.attendance)
                .then((response) => {
                    console.log("response peyechi",response);
                    if(response.data.data){
                        this.attendee = response.data.data
                        let temp_data = response.data.data
                        this.make_attendance = [];
                        temp_data.forEach(data=>{
                            let last_id = this.make_attendance.length ? this.make_attendance[this.make_attendance.length - 1].id : 0;
                            let start_time = data.attendance && data.attendance != null  ? data.attendance.start_time : '';
                            let end_time = data.attendance && data.attendance != null ? data.attendance.end_time : '';
                            console.log("data item", data.attendance, start_time, end_time)
                            this.make_attendance.push({
                                id:last_id + 1,
                                roaster_id: data.id,
                                status: data.attendance ? 'P' :"",
                                reason:"",
                                employee_id: data.employee_id,
                                emp_id: data.emp_id,
                                date:data.date,
                                leave_type:"",
                                start_time:start_time,
                                end_time: end_time,
                            })
                        })
                        console.log("amr make attendance peyechi",this.make_attendance)
                        this.$refs.attendanceform.reset();
                        this.fixedSuccess = response.data.message
                    }
                    else{

                    }
                    // this.$router.push('/add-attendance')
                }).catch((error) => {
                    this.loading = false
                })
            }
        },
        saveAttendance() {
            if (this.$refs.attendancesaveform.validate()) {
                let data = {
                    attendance:this.make_attendance
                }
                this.$store.dispatch("attendance/saveAttendance", data)
                .then((response) => {
                    if(response.data.data){
                        this.$refs.attendancesaveform.reset();
                        this.fixedSuccess = response.data.message
                        this.attendance = {
                            employee_id: "",
                            roaster_id: "",
                            date: "",
                            start_time: "",
                            end_time: "",
                        },
                        this.attendee = []
                        this.make_attendance = []
                        this.$router.push('/add-attendance').catch(()=>{});
                    }
                    else{

                    }
                    // this.$router.push('/add-attendance')
                }).catch((error) => {
                    this.loading = false
                })
            }
        }
    }
}
</script>
<style scoped>
    .table>:not(:last-child)>:last-child>*{
        border-color: transparent;
    }
    .card-header{
        background-color: #1a40bb;
        color: #fff;
    }
    .bootstrap-switch {
    border-color: #e5e5e5;
}
.bootstrap-switch {
    display: inline-block;
    cursor: pointer;
    border: 1px solid;
    position: relative;
    text-align: left;
    overflow: hidden;
    line-height: 8px;
    user-select: none;
    vertical-align: middle;
    min-width: 100px;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
    margin-left: -50%;
}
.bootstrap-switch .bootstrap-switch-container {
    display: inline-block;
    width: 150%;
    top: 0;
    /* border-radius: 4px;
    -webkit-transform: translate3d(0,0,0); */
    transform: translate3d(0,0,0);
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
    color: #fff;
    background: #45B6AF;
}
.bootstrap-switch .bootstrap-switch-handle-on, .bootstrap-switch .bootstrap-switch-handle-off {
    text-align: center;
    z-index: 1;
    width: 33.33333333%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block!important;
    height: 100%;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 14px;
    line-height: 20px;
}
.bootstrap-switch .bootstrap-switch-label {
    text-align: center;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 100;
    width: 33.33333333%;
    color: #333;
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block!important;
    height: 100%;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 14px;
    line-height: 20px;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
    color: #fff;
    background: #F3565D;
}
.bootstrap-switch input[type=radio], .bootstrap-switch input[type=checkbox] {
    position: absolute!important;
    top: 0;
    left: 0;
    opacity: 0;
    /* filter: alpha(opacity=0); */
    z-index: -1;
}
</style>
