export default {
    namespaced: true,
  
    state: () => ({
      payments: [],
      invoices: [],
      bank_accounts: []
    }),
  
    getters: {
      payments(state){
        return state.payments
      },
      invoices(state){
        return state.invoices
      },
      accounts(state){
        return state.bank_accounts
      }
    },
  
    mutations: {
      populatePayments(state, newPayment){
        state.payments = newPayment;
      },

      populateInvoices(state, newInvoice){
        state.invoices = newInvoice;
      },

      populateBankAccounts(state, newBankAccounts){
        state.bank_accounts = newBankAccounts;
      },

      savePayment(state,newPayment)
      {
        state.payments.push(newPayment);
      },
      updatePayment(state,newLeave)
      {
       
      }
    },
  
    actions: {
      async getPayments({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("payment")
          .then(res => {
            console.log("Payment Data::: ",res.data.data)
            resolve(res);
            commit("populatePayments",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async getInvoices({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("invoice")
          .then(res => {
            console.log("Invoice Data::: ",res.data.data)
            resolve(res);
            commit("populateInvoices",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async viewInvoice(ctx, apiArg){
        let isSuccess = false
        await axios.get("invoice/"+apiArg+"/edit").then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async getBankAccounts({ commit }){
        return new Promise((resolve, reject) => {
          axios.get("/xero/get_accounts")
          .then(res => {
            console.log("Payment Data::: ",res.data.accounts)
            resolve(res);
            commit("populateBankAccounts",res.data.accounts);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async EmployeePaymentDetails(ctx,apiArg){
        let isSuccess = false
        await axios.get("employee_payment_details/"+apiArg).then(response => {
          isSuccess = response.data.data
          console.log(response)
          return isSuccess
        })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
      },
      async AddPayment({ commit },apiArg){
        return new Promise((resolve, reject) => {
          const accessToken = localStorage.getItem("auth_token");
          if (accessToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
          }
          axios.post("payment",apiArg)
          .then(res => {
            console.log("Leave application Data::: ",res.data.data)
            resolve(res);
            commit("savePayment",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdatePayment({ commit },apiArg){
        return new Promise((resolve, reject) => {
          const accessToken = localStorage.getItem("auth_token");
          if (accessToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
          }
          axios.put("payment/"+apiArg.id,apiArg)
          .then(res => {
            console.log("Leave Application Data::: ",res.data.data)
            resolve(res);
            commit("updatePayment",res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async Paymentdetails(ctx,apiArg){
          let isSuccess = false
          await axios.get("payment/"+apiArg+"/edit").then(response => {
            isSuccess = response.data.data
            console.log(response)
            return isSuccess
          })
          .catch(error => {
              isSuccess = false;
              console.log(error)
          })
          return isSuccess;
        },
    }
  };