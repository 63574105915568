<template>
  <div class="container-fluid">
    <div class="container-wrapper">
      <div class="content-page-header mb-4">
        <div class="breadcrumb-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb content_shadow">
              <li class="breadcrumb-item">
                <router-link to="/invoice">Invoice</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">View</li>
            </ol>
          </nav>
        </div>
      </div>
      
      <!-- content-page-header end -->
      <div class="invoice-design shadow m-auto pt-2 p-5 mb-5" id="print">
        <div class="row print-button">
            <div class="float-right text-right">
                <button @click="printDiv('print')" class="btn btn-sm custom-btn mb-3 ml-1"><span><i class="fa fa-print"></i></span> Print</button>
            </div>
        </div>
        <div class="row">
          <div class="col-md-5 pt-5 mt-5 col-12">
            <h6>Employee Details</h6>
            <hr>
            <p class="mt-1 mb-2"><strong>Name: </strong>{{ invoice.employee ? invoice.employee.name : '' }}</p>
            <p class="mb-2"><strong>Email: </strong>{{ invoice.employee ? invoice.employee.email : '' }}</p>
            <p class="mb-2"><strong>Phone: </strong>{{ invoice.employee ? invoice.employee.phone : '' }}</p>
          </div>
          <div class="col-md-7">
            <div class="mt-2 align-right text-right">
                <h6>{{ invoice.details.length ? invoice.details[0].InvoiceNumber : '' }}</h6>
              </div>
            <div class="d-block">
              <div class="d-flex float-right align-center">

                <h4>Salary Invoice</h4>
                
                <img :src="invoice.company ? invoice.company.logo : require('@/assets/backend/img/logo-top.png')" alt="" class="logo">
              </div>
            </div>
              
          </div>
        </div>
        <div class="d-flex justify-space-between payment-period px-3 mt-4 py-4">
            <div>
              <p class="mb-0">Payment period: <span> {{ moment(invoice.created_at).startOf('month').format('Do MMM YYYY') }} to {{ moment(invoice.created_at).endOf('month').format('Do MMM YYYY') }}</span></p>
            </div>
            <div>
              <p class="mb-0">Date: <span>{{moment(invoice.created_at).format("Do MMM YYYY")}}</span></p>
            </div>
        </div>
        <div class="row">
          <div class="col-md-5 pt-5 mt-5 col-12">
            <h6>Employeer Details</h6>
            <hr>
            <p class="mt-1 mb-2">{{ invoice.company ? invoice.company.name : '' }}</p>
            <p class="mb-2">{{ invoice.company ? invoice.company.address : '' }}</p>
            <p class="mb-2">{{ invoice.company ? invoice.company.phone : '' }}</p>
          </div>
          <div class="col-md-7 pt-5 mt-5 col-12 text-right float-right">
            
            <p class="mt-1 mb-2"><strong>Status: </strong> <span class="text-primary">{{ invoice.details.length ? invoice.details[0].Status : '' }}</span></p>
            <p class="mt-1 mb-2"><strong>Creation Date: </strong>{{ invoice.details.length ? moment(invoice.created_at).format("Do MMM YYYY") : '' }}</p>
            
          </div>
          </div>
          <div class="row">
            <table class="table table-bordered table-stripe">
              <thead class="payment-period">
                <tr>
                  <th>Details</th>
                  <th>Type</th>
                  <th>Renumeration</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>{{invoice.designation ? invoice.designation.name : ''}}</td>
                  <td>{{"Bank Transfer ("+ (invoice.details.length ? invoice.details[0].bank_transfer_id : '')+" )"}}</td>
                  <td>{{ invoice.employee ? invoice.employee.regular_salary: '' }}</td>
                  <td>{{ invoice.amount }}</td>
                </tr>
                <tr style="border-bottom:none">
                  <td class="text-right border-none" colspan="3"><strong>Grand Total</strong></td>
                  <td>{{invoice.due + invoice.amount}}</td>
                </tr>
                <tr class="border-none">
                  <td class="text-right border-none" colspan="3"><strong>Paid</strong></td>
                  <td class="border">{{ invoice.amount }}</td>
                </tr>
                <tr class="border-none">
                  <td class="text-right border-none" colspan="3"><strong>Payable</strong></td>
                  <td class="border">{{invoice.due}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mt-5">
            <div class="text-right float-right">
                <p class="signature">Signature</p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from 'moment';
import DataTable from "vue-materialize-datatable";
export default {
  name: "Dashboard",

  data: () => ({
    
   invoice: {}
  }),
  components: {
    datatable: DataTable,
  },

  async created() {
    this.getInvoice();
  },
  computed: {
    
  },

  methods: {
    getInvoice(){
      this.$store
          .dispatch("payment/viewInvoice", this.$route.params.id)
          .then((res) => {
            if (res) {
              this.invoice = res
            }
          })
    },
    printDiv(divID) {
                var divContents = document.getElementById("print").innerHTML;
                var a = window.open('', '', '');
                a.document.write('<html><head><style type="text/css">');
                // a.document.write('<link rel="stylesheet" href="/assets/backend/css/bootstrap.min.css" type="text/css" />');
                a.document.write('body,td,th{font-family:Arial, Helvetica, sans-serif;font-size:13px;color:#000000}');
                a.document.write('.d-flex{display:flex}');
                a.document.write('body{margin:auto; max-width:980px;}');
                a.document.write('.text-right{text-align:right}');
                a.document.write('.print-button{display:none!important}');
                a.document.write('.float-right{float:right}');
                a.document.write('.justify-space-between {    justify-content: space-between;}');
                a.document.write('.px-3 {    padding-left: 12px !important;    padding-right: 16px !important;}');
                a.document.write('.col-md-7 {    flex: 0 0 auto;    width: 58.33333333%;}');
                a.document.write('.invoice-design{ max-width: 980px; }');
                a.document.write('.payment-period{background-color: #0084b1;color: #fff!important;  }');
                a.document.write('.payment-period th{color: #fff!important;  }');
                a.document.write('.col-md-5 { flex: 0 0 auto;    width: 41.66666667%;}');
                a.document.write('.row {    display: flex; text-align:left;    flex-wrap: wrap;    flex: 1 1 auto;}');
                a.document.write('thead tr th {color: rgb(0 0 0);    font-size: 13px;    height: 45px !important;}');
                a.document.write('table{border-collapse:collapse; margin:0 auto;width:100%;}');
                a.document.write('tbody, td, tfoot, th, thead, tr {border: 1px solid #e3ebf3 !important; text-align: center;}');
                a.document.write('thead th{font-size:13px!important;}');
                a.document.write('table td, table tr td {    padding: 3px 2px !important;}');
                a.document.write('table td, img {    height: 70px !important; width :70px !important}');
                a.document.write('.bottom-line{position:fixed; transform: translateX(-50%); left:50%;bottom:0}');
                a.document.write('</style></head><body>');
                a.document.write(divContents);
                a.document.write('<p class="bottom-line"> This invoice is system generated, no signiture needed </p>');
                a.document.write('</body></html>');
                a.document.close(); 
                a.focus();
                a.print();
                // document.body.innerHTML = oldPage;
            },
    editItem(props, event) {
      console.log("props data peyechi", props, event);
      this.$router
        .push({ name: "ViewInvoice", params: { id: props.id } })
        .catch(() => {});
    },
    deleteItem(props, e) {
      this.$swal({
        title: "Delete Leave Appliction",
        text: "Are you sure you want to delete this Leave application?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Delete!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed

          axios
            .delete("leave_application/" + props.id)
            .then((res) => {
              // this.feedlist = res.data;
              this.$swal("Successfully deleted");
              this.getLeaves();
            })
            .catch((err) => {
              console.log("Error", err);
            });
        }
      });
    },
  },
};
</script>

<style>
/* @import url(http://fonts.googleapis.com/icon?family=Material+Icons); */
.data-btn {
  background-color: #607d8b !important;
  color: #fff;
  padding: 5px 10px 5px 8px !important;
}
.custom-btn {
  background-color: #607d8b !important;
}
</style>
