import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import App from '../views/Layout.vue'
import loginAdmin from '../views/loginPage.vue'
import Dashboard from '../views/DashboardLayout.vue'
import Index from '../views/dashboard/index.vue'
import Department from '../views/dashboard/department/index.vue'
import AddDepartment from '../views/dashboard/department/add-department.vue'
import Designation from '../views/dashboard/designation/index.vue'
import addDesignation from '../views/dashboard/designation/add-designation.vue'
import editDesignation from '../views/dashboard/designation/edit-designation.vue'
import Leave from '../views/dashboard/leave/index.vue'
import Miscellaneous from '../views/dashboard/miscellaneous/index.vue'
import Payments from '../views/dashboard/payment/index.vue'
import Invoice from '../views/dashboard/invoice/index.vue'
import ViewInvoice from '../views/dashboard/invoice/view.vue'
import AddPayments from '../views/dashboard/payment/add-payments.vue'
import AddMiscellaneous from '../views/dashboard/miscellaneous/add-miscellaneous.vue'
import SalarySetting from '../views/dashboard/salary_setting/index.vue'
import SalarySummary from '../views/dashboard/salary/summary.vue'
import AddSalarySetting from '../views/dashboard/salary_setting/add_salary_setting.vue'
import SalarySlip from '../views/dashboard/salary/index.vue'
import SalaryProcess from '../views/dashboard/salary/process.vue'
import AddLeave from '../views/dashboard/leave/add-leave.vue'
import Employee from '../views/dashboard/employee/index.vue'
import AddEmployee from '../views/dashboard/employee/add-employee.vue'
import CompanySetting from '../views/dashboard/company_setting/add-company-setting.vue'
import Schedule from '../views/dashboard/schedule/add-schedule.vue'
import RoasterManagement from '../views/dashboard/roaster/index.vue'
import ProvidentFund from '../views/dashboard/provident/index.vue'
import AddProvidentFund from '../views/dashboard/provident/add-provident.vue'
import RoasterSwap from '../views/dashboard/roaster/swap.vue'
import RoasterReport from '../views/dashboard/roaster/report.vue'
import AddRoasterManagement from '../views/dashboard/roaster/add-roaster-management.vue'
import UserAdmin from '../views/dashboard/useradmin/index.vue'
import AddUserAdmin from '../views/dashboard/useradmin/add-useradmin.vue'
import EditUserAdmin from '../views/dashboard/useradmin/edit-useradmin.vue'
import ViewUserAdmin from '../views/dashboard/useradmin/view-useradmin.vue'
import Attendance from '../views/dashboard/attendance/index.vue'
import AttendanceReport from '../views/dashboard/attendance/report.vue'
import AddAttendance from '../views/dashboard/attendance/add-attendance.vue'
import EditAttendance from '../views/dashboard/attendance/edit-attendance.vue'
import ViewAttendance from '../views/dashboard/attendance/view-attendance.vue'
import Holidays from '../views/dashboard/holidays/index.vue'
import addHolidays from '../views/dashboard/holidays/add-holidays.vue'
import JobCard from '../views/dashboard/job_card/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: { title: "Home Layout" },
    component: loginAdmin
  },

  {
    meta: { title: "admin login"},
    path: "/admin",
    component: loginAdmin
  },
  
  {
    path: '/',
    meta: { title: "Dashboard Layout" },
    component: Dashboard,
    children: [
      {
        meta: { title: "Dashboard",requiresAuth:true },
        path: "/dashboard",
        component: Index,
      },
      {
        meta: { title: "Department",requiresAuth:true },
        path: "/department",
        component: Department,
      },
      {
        meta: { title: "Add Department",requiresAuth:true },
        path: "/add_department",
        component: AddDepartment,
      },
      {
        meta: { title: "Edit Department",requiresAuth:true},
        name: "EditDepartment",
        path: "/edit_department/:id",
        component: AddDepartment,
      },
      {
        meta: { title: "Employee designation",requiresAuth:true },
        path: "/designation",
        component: Designation,
      },
      {
        meta: { title: "Employee add designation",requiresAuth:true },
        path: "/add-designation",
        component: addDesignation,
      },
      {
        meta: { title: "Employee add designation",requiresAuth:true },
        path: "/edit-designation/:id",
        component: editDesignation,
        name: 'edit-designation',
      },

      /* Payments */
      {
        meta: { title: "Edit Payments",requiresAuth:true },
        path: "/edit-payment/:id",
        component: AddPayments,
        name: 'edit-payment',
      },
      {
        meta: { title: "Add Payments",requiresAuth:true },
        path: "/add-payment",
        component: AddPayments,
      },
      {
        meta: { title: "Payments",requiresAuth:true },
        path: "/payments",
        component: Payments,
      },
      /* Miscellaneous */
      {
        meta: { title: "Miscellaneous",requiresAuth:true },
        path: "/miscellaneous",
        component: Miscellaneous,
      },
      {
        meta: { title: "Add Miscellaneous",requiresAuth:true },
        path: "/add_miscellaneous",
        component: AddMiscellaneous,
      },
      {
        meta: { title: "Edit Miscellaneous",requiresAuth:true},
        name: "EditMiscellaneous",
        path: "/edit_miscellaneous/:id",
        component: AddMiscellaneous,
      },

      // salary setting
      {
        meta: { title: "Salary Setting",requiresAuth:true },
        path: "/salary_setting",
        component: SalarySetting,
      },
      {
        meta: { title: "Salary Summary",requiresAuth:true },
        path: "/salary_summary",
        component: SalarySummary,
      },
      {
        meta: { title: "Add Salary Setting",requiresAuth:true },
        path: "/add-salary-setting",
        component: AddSalarySetting,
      },
      {
        meta: { title: "Edit Salary Setting",requiresAuth:true },
        name: "EditSetting",
        path: "/edit-salary-setting/:id",
        component: AddSalarySetting,
      },
      // salary end
      
      /* Salary Slip */
      {
        meta: { title: "Salary Process",requiresAuth:true },
        path: "/salary_process",
        component: SalaryProcess,
      },
      {
        meta: { title: "Salary Slip",requiresAuth:true },
        path: "/salary_slip",
        component: SalarySlip,
      },
      /* Leave Application */
      {
        meta: { title: "Employee leave",requiresAuth:true },
        path: "/leave",
        component: Leave,
      },
      {
        meta: { title: "Add Employee Leave",requiresAuth:true },
        path: "/add_leave",
        component: AddLeave,
      },
      {
        meta: { title: "Edit Employee Leave",requiresAuth:true},
        name: "EditLeave",
        path: "/edit_leave/:id",
        component: AddLeave,
      },
      /* Employee */
      {
        meta: { title: "Employee",requiresAuth:true },
        path: "/employee",
        component: Employee,
      },
      {
        meta: { title: "Add Employee",requiresAuth:true },
        path: "/add_employee",
        component: AddEmployee,
      },

      {
        meta: { title: "Edit Employee",requiresAuth:true},
        name: "EditEmployee",
        path: "/edit_employee/:id",
        component: AddEmployee,
      },

      /* company settings */
      {
        meta: { title: "Company setting",requiresAuth:true },
        name: "CompanySetting",
        path: "/company_settings",
        component: CompanySetting,
      },

      /* schedule settings */
      {
        meta: { title: "Schedule setting", requiresAuth:true },
        name: "Schedule",
        path: "/schedule",
        component: Schedule,
      },

      /* Invoice */
      {
        meta: { title: "Invoice View",requiresAuth:true },
        name: "Invoice",
        path: "/invoice",
        component: Invoice,
      },
      {
        meta: { title: "Invoice View",requiresAuth:true },
        name: "ViewInvoice",
        path: "/view_invoice/:id",
        component: ViewInvoice,
      },

      // provident fund manage
      {
        meta: { title: "Provident Fund management",requiresAuth:true },
        path: "/provident_fund",
        component: ProvidentFund,
      },
      {
        meta: { title: "Add Provident Fund",requiresAuth:true },
        path: "/add_provident_fund",
        component: AddProvidentFund,
      },
      {
        meta: { title: "Edit Provident Fund",requiresAuth:true},
        name: "EditProvidentFund",
        path: "/edit_provident_fund/:id",
        component: AddProvidentFund,
      },

         /* Roaster Management */
         {
          meta: { title: "Roaster management",requiresAuth:true },
          path: "/roaster_management",
          component: RoasterManagement,
        },
         {
          meta: { title: "Roaster Swap",requiresAuth:true },
          path: "/roaster_swap",
          component: RoasterSwap,
        },
         {
          meta: { title: "Roaster Report",requiresAuth:true },
          path: "/roaster_report",
          component: RoasterReport,
        },
        {
          meta: { title: "Add RoasterManagement",requiresAuth:true },
          path: "/add_roaster",
          component: AddRoasterManagement,
        },
  
        {
          meta: { title: "Edit Roaster",requiresAuth:true},
          name: "EditRoaster",
          path: "/edit_roaster_management/:id",
          component: AddRoasterManagement,
        },

      /* Admin */
      {
        meta: { title: "User Admin",requiresAuth:true },
        path: "/useradmin",
        component: UserAdmin,
      },
      {
        meta: { title: "Add User Admin",requiresAuth:true },
        path: "/add-useradmin",
        component: AddUserAdmin,
      },
      {
        meta: { title: "Edit User Admin",requiresAuth:true},
        name: "edit-useradmin",
        path: "/edit-useradmin/:id",
        component: EditUserAdmin,
      },
      /* Attendance */
      {
        meta: { title: "Attendance",requiresAuth:true },
        path: "/attendance_report",
        component: AttendanceReport,
      },
      {
        meta: { title: "Attendance",requiresAuth:true },
        path: "/attendance",
        component: Attendance,
      },
      {
        meta: { title: "Add Attendance",requiresAuth:true },
        path: "/add-attendance",
        component: AddAttendance,
      },
      {
        meta: { title: "Edit Attendance",requiresAuth:true},
        name: "edit-attendance",
        path: "/edit-attendance/:id",
        component: EditAttendance,
      },
      {
        meta: { title: "View Attendance", requiresAuth:true},
        path: "/view-attendance/:id",
        name: 'view-attendance',
        component: ViewAttendance,
      },

      {
        meta: { title: "User Admin Profile View", requiresAuth:true},
        name: "view-useradmin",
        path: "/view-useradmin/:id",
        component: ViewUserAdmin,
      },
      {
        meta: { title: "Holidays",requiresAuth:true },
        path: "/holidays",
        component: Holidays,
        name: 'holidays',
        
      },
      {
        meta: { title: "Add holidays",requiresAuth:true },
        path: "/add-holidays",
        component: addHolidays,
        name: 'add-holidays'
      },
      /* Job Card */
      {
        meta: { title: "Job Card",requiresAuth:true },
        path: "/job_card",
        component: JobCard,
      },
    ]
  },

]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // page scroll to top for all route navigations
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("auth_token") == null) {
      next({
        path: "/admin",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("auth_token") == null) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

export default router
