export default {
    namespaced: true,
    state: () => ({
      attendance_schedule: []
    }),
  
    getters: {
      attendance_schedule(state){
        return state.attendance_schedule
      },
    },
    mutations: {
      saveAttendanceSchedule(state,newAttendanceSchedule) {
        state.attendance_schedule.push(newAttendanceSchedule);
      },
      updateAttendanceSchedule(state,newAttendanceSchedule) {
        state.attendance_schedule.push(newAttendanceSchedule);
      },
      populateAttendanceSchedule(state, newAttendanceSchedule) {
        state.attendance_schedule = newAttendanceSchedule
      }
    },
  
    actions: {
      async saveAttendanceSchedule({ commit }, apiArg){
        return new Promise((resolve, reject) => {
          axios.post("attendance_schedule", apiArg)
          .then(res => {
            resolve(res);
            commit("saveAttendanceSchedule", res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
      async UpdateAttendanceSchedule({ commit },apiArg){
        return new Promise((resolve, reject) => {
          axios.put("attendance_schedule/"+apiArg.id,apiArg)
          .then(res => {
            resolve(res);
            commit("updateAttendanceSchedule", res.data.data);
          })
          .catch(error => {
            reject(error)
                console.log("Something Error")
            })
          });
      },
 
        async getAttendanceSchedule({ commit }){
          return new Promise((resolve, reject) => {
            axios.get("attendance_schedule").then(res => {
              resolve(res.data.data);
              commit("populateAttendanceSchedule", res.data.data);
            })
            .catch(error => {
              reject(error)   
                  console.log("Something Error")
              })
            });
        },
    }
  };